<template>
	<div class="articleTalent">
		<w-navTab titleText="人才库"></w-navTab>
		<div class="container">
			<w-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="middle" v-show="newsList.length > 0">
					<NewsList :news-list="newsList"></NewsList>
				</div>
			</w-list>
		</div>
	</div>
</template>

<script>
import NewsApi from '@/api/news';
import NewsList from '@/components/NewsList';
import VantVendor from '@/vendor/vant';
import WList from '@/components/List';
import keepMiXin from '@/mixins/keep';
import scrollMiXin from '@/mixins/scroll';
import UtilsHelper from '@/vendor/utils';

const keep = keepMiXin(['NewsDetail']);
const scroll = scrollMiXin(['.list-view']);

export default {
	name: 'ArticleTalent',
	mixins: [scroll, keep],
	inject: ['className'],
	data() {
		return {
			newsList: [],
			loading: true,
			finished: false,
			isEmpty: false,
			paramsPage: 1,
			paramsLimit: 10,
		};
	},
	created() {
		this.onLoad()
	},
	methods: {
		onLoad() {
			this.loading = true;

			let params = {
				page: this.paramsPage,
				limit: this.paramsLimit,
				column_id: 108
			};

			NewsApi.articleList(params)
				.then(result => {
					this.paramsPage++;
					this.newsList = this.newsList.concat(result.data.list);

					this.loading = false;

					if (this.newsList.length <= 0) {
						this.isEmpty = true;
					}

					if (this.newsList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		}
	},
	components: {
		WList,
		NewsList
	}
};
</script>

<style lang="less" scoped>
.articleTalent {
	.container{
		padding: 10px;
		box-sizing: border-box
	}
	.list-view{
		height: 100vh;
	}
	.head-nav {
		background-color: #fff;

		.head-nav-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 18px 20px 0;
			border-bottom: 1px solid #ddd;

			.color {
				font-weight: bold;
			}
		}

		div {
			font-size: 16px;
			line-height: 23px;
			color: #000;
			padding-bottom: 12px;
			margin-bottom: -1px;
		}
	}

	.head-nav-top.head-nav-top-city .color {
		color: #3377ff;
		border-bottom: 2px solid #3377ff;
	}

	.head-nav-top.head-nav-top-district .color {
		color: #ff6969;
		border-bottom: 2px solid #ff6969;
	}

	.middle {
		box-shadow: 0px 0px 0.08rem rgba(51, 119, 255, 0.1);
	}
}
</style>
