<template>
	<div class="news-list">
		<div class="news-item" v-if="propsShow == false" v-for="vo in newsList"  @click="$router.push({ name: 'NewsDetail',params:{id:vo.id}})">
			<div class="news-item-left"><img :src="vo.picture" alt="" /></div>
			<div class="news-item-right">
				<div class="news-item-title">{{ vo.title }}</div>
				<div class="item-box">
					<div class="item-box-left">
						<div class="box-left-picture">
							<img src="@/assets/img/icon210.png" alt="" />
						</div>
						<div class="box-left-title" v-if="vo.district_name == '上饶市'">{{ vo.district_name + "平安义警协会" }}</div>
						<div class="box-left-title" v-else>{{ vo.district_name + "平安义警分会" }}</div>
					</div>
					<div class="item-box-right">
						<div class="box-right-picture">
							<template v-if="districtType() == 1">
								<img src="@/assets/img/icon_views_city.png" />
							</template>
							<template v-if="districtType() == 2">
								<img src="@/assets/img/icon_views_district.png" />
							</template>
						</div>
						<div class="box-right-title">{{ vo.views }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="news-item" v-if="propsShow == true" v-for="vo in newsList"  @click="$router.push({ name: 'NewsFile',params:{id:vo.id}})">
			<div class="news-item-left"><img :src="vo.picture" alt="" /></div>
			<div class="news-item-right">
				<div class="news-item-title">{{ vo.title }}</div>
				<div class="item-box">
					<div class="item-box-left">
						<div class="box-left-picture">
							<img src="@/assets/img/icon210.png" alt="" />
						</div>
						<div class="box-left-title" v-if="vo.district_name == '上饶市'">{{ vo.district_name + "平安义警协会" }}</div>
						<div class="box-left-title" v-else>{{ vo.district_name + "平安义警分会" }}</div>
					</div>
					<div class="item-box-right">
						<div class="box-right-picture">
							<template v-if="districtType() == 1">
								<img src="@/assets/img/icon_views_city.png" />
							</template>
							<template v-if="districtType() == 2">
								<img src="@/assets/img/icon_views_district.png" />
							</template>
						</div>
						<div class="box-right-title">{{ vo.views }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['className', 'districtType', 'themeColor'],
	props: {
		newsList: {
			default: []
		},
		propsShow:{
			default: false
		}
	},

};
</script>

<style lang="less" scoped>
.news-list {
	padding: 10px;
	border-radius: 8px;
	background-color: #fff;

	.news-item {
		display: flex;
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		.news-item-left {
			width: 132px;
			height: 90px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 6px;
			}
		}

		.news-item-right {
			flex: 1;
			padding-left: 10px;

			.news-item-title {
				font-size: 18px;
				height: 46px;
				min-height: 46px;
				max-height: 46px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			.item-box {
				margin-top: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.item-box-left {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.box-left-picture {
						width: 16px;
						min-width: 16px;
						height: 16px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.box-left-title {
						margin-left: 5px;
						font-size: 14px;
						line-height: 16px;
						color: #666;
					}
				}

				.item-box-right {
					display: flex;
					align-items: center;

					.box-right-picture {
						width: 16px;
						min-width: 16px;
						height: 16px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.box-right-title {
						margin-left: 5px;
						font-size: 12px;
						line-height: 16px;
						color: #666;
					}
				}
			}
		}
	}
}
</style>
